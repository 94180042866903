<template>
  <div>
    <aad-title>Sessions</aad-title>

    <aad-loader
      v-if="allSessions == undefined || allSessions.length == 0"
    ></aad-loader>

    <div v-else class="row">
      <div class="col-xl-4 p-1">
        <a
          class="btn btn-primary-solid d-xl-none mb-4"
          href="#"
          @click="toggleFilters"
          >{{ mobileFiltersVisible ? "Hide Filters" : "View Filters" }}
        </a>

        <!-- Search Section -->
        <div class="d-xl-block" :class="{ 'd-none': !mobileFiltersVisible }">
          <session-search-filters-search-term></session-search-filters-search-term>

          <session-search-filters-favorites></session-search-filters-favorites>

          <session-search-filters-whats-on-now></session-search-filters-whats-on-now>

          <session-search-filters-current> </session-search-filters-current>

          <!-- Filters -->
          <div v-for="(filter, key) in sessionSearchFilters" :key="key">
            <session-search-filters-list
              v-if="filter.isFilterList"
              :filter-key="key"
              :filter="filter"
              :items="getComputedByName(filter.getter)"
            ></session-search-filters-list>
          </div>
          <hr class="d-xl-none my-5" />
        </div>
      </div>

      <!-- Session List -->
      <div class="col-xl-8 p-1">
        <h2 class="px-2">
          {{ filteredSessions.length }} Sessions Found
          <span v-if="anySessionSearchFilters">With Current Filters</span>
        </h2>
        <div v-if="filteredSessions && filteredSessions.length > 0">
          <div v-for="session in filteredSessions" :key="session.id">
            <session-search-session-card
              :session="session"
            ></session-search-session-card>
          </div>
        </div>
        <div v-else>
          <div class="px-4">No sessions match these filters.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SessionSearchFiltersCurrent from "@/components/SessionSearchFiltersCurrent.vue";
import SessionSearchFiltersList from "@/components/SessionSearchFiltersList.vue";
import SessionSearchFiltersSearchTerm from "@/components/SessionSearchFiltersSearchTerm.vue";
import SessionSearchSessionCard from "@/components/SessionSearchSessionCard.vue";
import SessionSearchFiltersFavorites from "../components/SessionSearchFiltersFavorites.vue";
import SessionSearchFiltersWhatsOnNow from "../components/SessionSearchFiltersWhatsOnNow.vue";
export default {
  mounted() {
    this.getSessions();

    this.mobileFiltersVisible = this.anySessionSearchFilters;
  },
  metaInfo: {
    title: "Sessions",
  },
  components: {
    SessionSearchFiltersCurrent,
    SessionSearchFiltersList,
    SessionSearchFiltersSearchTerm,
    SessionSearchSessionCard,
    SessionSearchFiltersFavorites,
    SessionSearchFiltersWhatsOnNow
  },
  data() {
    return {
      mobileFiltersVisible: false,
    };
  },
  methods: {
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    getComputedByName(computedName) {
      return this[computedName];
    },
    toggleFilters() {
      this.mobileFiltersVisible = !this.mobileFiltersVisible;
    },
  },
  computed: {
    ...mapGetters([
      "anySessionSearchFilters",
      "categoriesInSessions",
      "datesOfSessions",
      "categoriesInSessions",
      "typesInSessions",
      "filteredSessions",
      "allSessions",
    ]),
    ...mapState({
      sessionSearchFilters: (state) => state.sessionSearch.sessionSearchFilters,
    }),
  },
};
</script>
